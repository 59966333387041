import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React, { useContext, useEffect, useState } from "react"

import { GlobalStateContext } from "../../context/GlobalContextProvider"
import { translateContent } from "../../utils/translate-content"

export const Product = ({ product, tags }) => {
  const {
    productAlcohol,
    productDescription,
    productDescriptionPl,
    productEanCode,
    productExtract,
    productImage,
    productIngredients,
    productIngredientsPl,
    productStyle,
    productTitle
  } = product
  const imageData = getImage(productImage.localFile)
  const { language } = useContext(GlobalStateContext)

  const [ingredients, setIngredients] = useState("")

  useEffect(() => {
    if (language === "pl") {
      setIngredients(productIngredientsPl)
    } else {
      setIngredients(productIngredients)
    }
  }, [language])

  const translateKeyWords = (lang, wordEn, wordPl) => {
    const keysList = {
      stylePl: "Styl",
      style: "Style",
      ingredientsPl: "Składniki",
      ingredients: "Ingredients",
      alcoholPl: "Alkohol",
      alcohol: "Alcohol",
      extractPl: "Ekstrakt",
      extract: "Extract"
    }

    return lang === "en" ? keysList[wordEn] : keysList[wordPl]
  }

  return (
    <div className="content-wrapper product">
      <div className="product__container">
        <div className="product__container--west">
          <GatsbyImage image={imageData} className="product__container--west__image" alt={`moon lark-${product.slug}`} />
          <p className="product__container--west__title">{productTitle}</p>
          <div className="product__container--west__tags-container">
            {tags.map((tag) => (
              <p key={tag.id} className="product__container--west__tag">
                {tag.name.split("-")[0]}
              </p>
            ))}
          </div>
        </div>

        <div className="product__container--east">
          <p className="product__container--east__style">
            {translateKeyWords(language, "style", "stylePl")}: {productStyle}
          </p>

          <p className="product__container--east__description">{translateContent(language, productDescription, productDescriptionPl)} </p>

          <div className="product__container--east__ingredients">
            <p>
              {translateKeyWords(language, "ingredients", "ingredientsPl")}: {ingredients}
            </p>
          </div>

          <p className="product__container--east__alcohol">
            {translateKeyWords(language, "alcohol", "alcoholPl")}: {productAlcohol} % obj.
          </p>

          {productExtract && (
            <p className="product__container--east__extract">
              {translateKeyWords(language, "extract", "extractPl")}: {productExtract}
            </p>
          )}

          <p className="product__container--east__ean">EAN: {productEanCode}</p>
        </div>
      </div>
    </div>
  )
}
