import * as React from "react"
import { graphql } from "gatsby"

import { BackgroundLayout } from "../components/BackgroundLayout/BackgroundLayout"
import { Footer } from "../components/Footer/Footer"
import { Layout } from "../components/Layout/Layout"
import { Product } from "../components/Product/Product"
import { SEO } from "../components/SEO/Seo"

import "../sass/main.scss"

const IndexPage = ({ data }) => {
  const {
    wpProduct: { product, tags }
  } = data

  return (
    <main>
      <Layout>
        <BackgroundLayout>
          <Product product={product} tags={tags.nodes} />
          <Footer />
        </BackgroundLayout>
      </Layout>
    </main>
  )
}

export default IndexPage

export const Head = ({ data }) => {
  const {
    wpProduct: { product, slug }
  } = data
  return <SEO title={product.productTitle} pathname={`/${product.productType}/${slug}`} />
}
export const query = graphql`
  query ($slug: String) {
    wpProduct(slug: { eq: $slug }) {
      slug
      tags {
        nodes {
          id
          name
        }
      }
      product {
        productTitle
        productType
        productStyle
        productIngredientsPl
        productIngredients
        productExtract
        productEanCode
        productDescriptionPl
        productDescription
        productAlcohol
        productImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
          link
        }
      }
    }
  }
`
